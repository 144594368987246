import Event from '@/services/eventBus'
import Form from '@/utils/form'

import { mapActions, mapState } from 'vuex'
import { flatMap, pick, map } from 'lodash'

export default {
  data() {
    return {
      form: new Form({
        id: this.$attrs.id,
        in_tutorial_library: false,
        is_prenatal: false,
        is_explainer_video: false,
        is_postnatal: false,
        code: null,
        title: null,
        trainer_id: null,
        trainer: {},
        description: null,
        equipments: [],
        video_public_id: null,
        tutorial_video_public_id: null,
        instructions: [],
        alternatives: [],
        completed: false,
      }),
    }
  },

  computed: {
    ...mapState({
      selectedExercise: (state) => state.exercises.selectedExercise,
    }),

    isCompleted() {
      return !!this.selectedExercise.completed_at
    },

    hasChanges() {
      return this.form.$isDirty()
    },
  },

  methods: {
    ...mapActions({
      saveExercise: 'exercises/saveExercise',
    }),

    fillForm() {
      let data = pick(this.selectedExercise, Object.keys(this.form.$values))

      data.instructions = data.instructions || []
      data.completed = this.isCompleted

      this.form = new Form({ ...this.form.$values, ...data })
    },

    async save() {
      let data = {}

      if (!this.form.id || this.hasChanges) {
        this.form.$busy = true
        this.form.$clearErrors()

        try {
          data = await this.saveExercise(this.preparedData())
        } catch ({ response }) {
          data = { error: { response } }
        }

        this.form.$timeout(() => {
          if (data.error && data.error.response.status === 422) {
            this.form.$setErrors(data.error.response.data.errors)
          }
          this.form.$busy = false
        })
      }

      return data
    },

    preparedData() {
      let data = { ...this.selectedExercise, ...this.form }
      data.equipments = map(this.form.equipments, (equipment) => {
        return typeof equipment === 'object' ? equipment.id : equipment
      })

      data.alternatives = map(flatMap(data.alternatives), (alternative) => {
        return {
          exercise_id: alternative.id,
          type: alternative.exercise_alternative.type,
        }
      })

      return pick(data, Object.keys(this.form.$values))
    },

    redirectIf(condition, routeName) {
      if (condition) {
        this.$router.replace({
          name: routeName,
          params: { id: this.selectedExercise.id },
        })
      }
      return condition
    },

    redirectToListView() {
      if (this.isCompleted) {
        Event.$emit('exercise-activated')
        this.$router.push({ name: 'active.exercises' })
      } else {
        Event.$emit('draft-updated')
        this.$router.push({ name: 'draft.exercises' })
      }
    },
  },

  watch: {
    selectedExercise() {
      this.fillForm()
    },
  },

  created() {
    if (this.$attrs.id) {
      this.fillForm()
    }
  },
}
