<template>
  <div class="bbr-exercises--search-exercise-input">
    <v-autocomplete
      v-model="exercise"
      :label="label"
      :readonly="readonly"
      :items="filteredExercises"
      :hide-no-data="!search || hideNoData"
      :hide-details="!hasErrors"
      :search-input.sync="search"
      :return-object="returnObject"
      :error-messages="searchInputErrors"
      @change="selectedExrcise"
      item-text="title"
      item-value="id"
      outlined
      flat
    >
      <template v-slot:append>
        <v-fade-transition leave-absolute>
          <v-progress-circular
            v-if="loading"
            size="24"
            color="primary"
            indeterminate
          />
        </v-fade-transition>
      </template>

      <template
        v-if="
          filteredExercises.length > 1 && search && search !== '' && addButton
        "
        v-slot:prepend-item
      >
        <div class="ma-3">
          <v-btn color="primary" @click="addNewExercise" block>
            Add <span class="yellow--text mx-1"> {{ search }} </span> as a New
            Exercise
          </v-btn>
        </div>
      </template>

      <template v-else-if="noDataButton" v-slot:no-data>
        <div class="pa-3 text-center">
          <div class="mb-4">
            <span>
              No results matching "<strong>{{ search }} </strong>".
            </span>
          </div>
          <v-btn color="primary" @click="addNewExercise" block>
            Add as a New Exercise
          </v-btn>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import Exercise from '@/models/Exercise'

import { map, flatMap, isEmpty, debounce } from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'SearchExercisesInput',

  props: {
    value: {
      type: [Number, Object],
    },
    defaultValue: {
      type: [Object, Array],
    },
    label: {
      type: String,
      default: 'Search exercises',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: [Object, Array],
    },
    completed: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideNoData: {
      type: Boolean,
      default: false,
    },
    clearOnselect: {
      type: Boolean,
      default: false,
    },
    withEquipments: {
      type: Boolean,
      default: false,
    },
    noDataButton: {
      type: Boolean,
      default: false,
    },
    excludedItems: {
      type: Array,
    },
    addButton: {
      type: Boolean,
      default: true,
    },
    withTrainer: {
      type: Boolean,
      default: false,
    },
    trainer: {
      type: [Object, String],
    },
  },

  data() {
    return {
      search: '',
      exercise: '',
      exercises: [],
      excludedExercises: [],
      isLoading: this.loading,
    }
  },

  created() {
    if (!isEmpty(this.defaultValue)) {
      this.setDefaultValue()
    }

    this.excludedExercises = this.excludedItems
  },

  computed: {
    ...mapState({
      selectedWorkout: (state) => state.workouts.selectedWorkout,
    }),

    searchInputErrors() {
      return Array.isArray(this.errors) && this.exercise
        ? this.errors
        : flatMap(this.errors)
    },

    hasErrors() {
      return !!this.searchInputErrors.length
    },

    filteredExercises() {
      const items = map(this.excludedItems, (item) => {
        return item.id ? item.id : item.exercise_id
      }).filter((item) => item)

      const excludedItems = items.filter((item) => {
        if (items && item !== this.value) {
          return item
        }
      })

      if (excludedItems.length) {
        return this.exercises.filter((exercise) => {
          if (!excludedItems.includes(exercise.id)) {
            return exercise
          }
        })
      }

      return this.exercises
    },
  },

  methods: {
    searchExercises: debounce(function() {
      this.isLoading = true

      this.fetchSearchedExercises().then(({ data }) => {
        this.isLoading = false

        if (isEmpty(data)) return

        this.exercises = data
      })
    }, 250),

    async fetchSearchedExercises() {
      let query = await Exercise.page(1).where('search', this.search)

      if (this.completed) {
        query.where('completed', this.completed)
      }

      if (this.withTrainer) {
        query.where('trainer', this.trainer.id)
      }

      if (this.withEquipments) {
        query.include(['equipments'])
      }

      return query.params({ limit: 100 }).get()
    },

    selectedExrcise(exercise) {
      if (this.clearOnselect) {
        this.search = null
        this.exercise = null
        this.exercises = []
      }
      this.$emit('input')
      this.$emit('selected', exercise)
    },

    addNewExercise() {
      this.$emit('add-new', this.search)
      this.search = null
    },

    setDefaultValue() {
      this.exercise = this.value

      if (Array.isArray(this.defaultValue)) {
        this.exercises.push(...this.defaultValue)
      } else {
        this.exercises.push(this.defaultValue)
      }
    },
  },

  watch: {
    defaultValue() {
      this.setDefaultValue()
    },

    search(value) {
      if (value) {
        this.searchExercises()
      }
    },

    exercise(value) {
      this.$emit('input', value)
    },
  },
}
</script>
