<template>
  <div class="bbr-exercises--new-exercises-swaps">
    <v-row>
      <v-col lg="8" md="12">
        <div class="mb-4 d-flex justify-space-between align-center">
          <h2 class="mr-auto">Exercise Swaps</h2>

          <v-btn
            v-if="!form.completed"
            class="mr-5 px-7"
            color="primary"
            :loading="form.$busy"
            text
          >
            <span>Save & Finish Later</span>
          </v-btn>

          <v-btn
            class="px-12 bg-primary-gradient primary next-button"
            :loading="form.$busy"
            @click="activeExercise"
            depressed
            text
          >
            <span v-if="!form.completed"> Activate Exercise </span>
            <span v-else> Save Exercise </span>
            <v-icon> {{ icons.check }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col lg="8" md="12">
        <swap-exercises
          class="mb-7"
          title="Regressions"
          :type="1"
          :items="exerciseRegressions"
        />

        <swap-exercises
          class="mb-7"
          title="Progressions"
          :type="2"
          :items="exerciseProgressions"
        />

        <swap-exercises
          class="mb-7"
          title="Swaps"
          :type="3"
          :items="exerciseSwaps"
        />

        <swap-exercises
          class="mb-7"
          title="No Equipments"
          :type="4"
          :items="exerciseNoEquipments"
        />
      </v-col>
    </v-row>

    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.open"
      :timeout="2000"
      bottom
      right
    >
      {{ snackbar.msg }}
      <v-btn class="px-5" @click="snackbar.open = false" dark text>
        <span> Close </span>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import SwapExercises from '@/components/forms/exercises/SwapExercises'
import ExerciseMixin from '../mixins/ExerciseMixin'

import { mapGetters } from 'vuex'

import { mdiArrowRight, mdiCheck } from '@mdi/js'

export default {
  name: 'ExerciseSwaps',

  mixins: [ExerciseMixin],

  components: {
    SwapExercises,
  },

  data() {
    return {
      icons: {
        next: mdiArrowRight,
        check: mdiCheck,
      },
      snackbar: {
        msg: null,
        open: false,
        color: null,
      },
    }
  },

  computed: {
    ...mapGetters({
      exerciseSwaps: 'exercises/getSelectedExerciseSwaps',
      exerciseRegressions: 'exercises/getSelectedExerciseRegressions',
      exerciseProgressions: 'exercises/getSelectedExerciseProgressions',
      exerciseNoEquipments: 'exercises/getSelectedExerciseNoEquipments',
    }),
  },

  methods: {
    async activeExercise() {
      this.form.completed = true
      let saveResult = await this.save()
      if (saveResult.error) {
        this.form.completed = false
        if (this.form.$getError('instructions')) {
          this.snackbar.open = true
          this.snackbar.color = 'error'
          this.snackbar.msg = 'Instrucions are required.'
        }
      } else {
        this.redirectToListView()
      }
    },
  },
}
</script>
