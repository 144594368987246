<template>
  <div class="bbr-exercises--exercise-swaps">
    <v-row no-gutters>
      <v-col cols="12">
        <div class="d-flex align-center py-2 ">
          <h2 class="title primary--text">{{ title }}</h2>
          <v-btn
            v-if="!onEdit"
            class="ml-auto"
            color="primary"
            @click="onEdit = true"
            text
          >
            <v-icon> {{ icons.edit }} </v-icon>
            <span class="ml-2"> Edit {{ title }} </span>
          </v-btn>

          <v-btn
            v-else
            class="ml-auto px-12"
            color="primary"
            @click="onEdit = false"
            depressed
            dark
          >
            <span> Done Editing </span>
          </v-btn>
        </div>

        <v-divider class="primary" />
      </v-col>

      <v-col v-if="onEdit" cols="12" class="clearfix">
        <div class="col-6 py-5 float-right">
          <search-exercises
            v-model="exercise"
            :errors="errors"
            :label="`Search ${singularTitle} Exercises`"
            :add-button="false"
            :excludedItems="exercises"
            :trainer="currentExercise.trainer"
            @selected="selectedExercise"
            clear-onselect
            return-object
            with-trainer
            clearable
            completed
          />
        </div>
      </v-col>

      <v-col cols="12">
        <div v-if="hasExercises" class="swap-exercises">
          <v-divider />
          <div
            class="border-bottom"
            v-for="exercise in exercises"
            :key="exercise.id"
          >
            <div class="d-flex align-center px-2 py-5">
              <span>{{ exercise.title }}</span>
              <v-btn
                v-if="onEdit"
                class="ml-auto"
                color="secondary"
                @click="removeSelectedExercise(exercise)"
                icon
              >
                <v-icon> {{ icons.remove }} </v-icon>
              </v-btn>
            </div>
          </div>
        </div>

        <div v-else>
          <v-divider />
          <h2 class="headline grey--text py-5">
            No {{ singularTitle }} Exercises
          </h2>
        </div>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="2000"
      bottom
      right
    >
      {{ snackbarMessage }}
      <v-btn @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import SearchExercises from '@/components/elements/exercises/SearchExercisesInput'
import { mdiPencil, mdiTrashCanOutline } from '@mdi/js'
import Swaps from '@/models/Swaps'
import { mapState, mapMutations } from 'vuex'
import { clone, flatMap } from 'lodash'

export default {
  name: 'SwapExercises',

  props: {
    title: {
      type: String,
      default: 'Exercise Swaps',
    },

    type: {
      type: Number,
      required: true,
    },

    items: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
  },

  components: {
    SearchExercises,
  },

  data() {
    return {
      onEdit: false,
      exercise: null,
      snackbar: false,

      errors: {},
      exercises: [],
      exercisesCopy: [],
      snackbarMessage: '',
      snackbarColor: 'success',

      icons: {
        edit: mdiPencil,
        remove: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    ...mapState({
      currentExercise: (state) => state.exercises.selectedExercise,
    }),

    hasExercises() {
      return !!this.exercises.length
    },

    singularTitle() {
      return this.title.slice(0, -1)
    },
  },

  created() {
    this.exercises = clone(this.items)
  },

  methods: {
    ...mapMutations({
      setSwapExercise: 'exercises/setSwapExercise',
      removeSwapExercise: 'exercises/removeSwapExercise',
    }),

    clearErrors() {
      this.errors = {}
    },

    async selectedExercise(exercise) {
      this.clearErrors()

      if (!exercise) return

      let swapExercise = new Swaps({
        exercise_id: exercise.id,
        type: this.type,
      })
        .for(this.currentExercise)
        .save()

      try {
        let data = await swapExercise
        this.snackbar = true
        this.snackbarColor = 'success'
        this.snackbarMessage = `New ${this.singularTitle} Exercise Added`

        exercise.exercise_alternative = { id: Number(data.id), type: this.type }

        this.setSwapExercise({
          exercise: exercise,
          type: this.type,
        })
      } catch ({ response }) {
        if (response && response.status === 422) {
          this.errors = flatMap(response.data.errors)
        }
      }
    },

    async removeSelectedExercise(exercise) {
      let removeSwapExercise = new Swaps({
        type: this.type,
        exercise_id: exercise.id,
        id: exercise.exercise_alternative.id,
      })
        .for(this.currentExercise)
        .delete()

      try {
        await removeSwapExercise

        this.snackbar = true
        this.snackbarColor = 'success'
        this.snackbarMessage = `${this.singularTitle} Exercise Deleted`

        this.removeSwapExercise({ exercise, type: this.type })
      } catch ({ response }) {
        return response
      }
    },
  },

  watch: {
    items(items) {
      this.exercises = items
    },
  },
}
</script>

<style lang="scss" scoped>
.border-bottom {
  border: solid;
  border-width: 0 0 thin 0;
  border-color: rgba(0, 0, 0, 0.12) !important;
}
</style>
